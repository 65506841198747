// Havforum colors
$color-vannportalen--blue-light: #e8f3ff;
$color-vannportalen--blue-light-1: #5d92c6;
$color-vannportalen--blue-light-2: #c3dbf4;
$color-vannportalen--blue-light-3: #ebf3f9;
$color-vannportalen--blue-light-4: #e5f2ff;
$color-vannportalen--blue-light-5: #edf6ff;
$color-vannportalen--blue-light-6: #f8fcff;
$color-vannportalen--blue-dark: #004990;
$color-vannportalen--blue-dark-1: #00274d;
$color-vannportalen--blue-dark-2: #11273c;
$color-vannportalen--blue-dark-3: #194990;

$color-vannportalen-link: #004990;
$color-vannportalen-link-1: #1c4264;

$color-vannportalen--border-1: #d6e1e8;
$color-vannportalen--border-2: #dee8d6;
$color-vannportalen--border-3: #D4D7DD;

$colors-process-bar-1: #4E91F8;
$colors-process-bar-2: #614FC1;
$colors-process-bar-3: #009B96;
$colors-process-bar-4: #4A6FA7;
$colors-process-bar-5: #C15296;
$colors-process-bar-6: #C4A10D;
$colors-process-bar-7: #8D8496;
$colors-process-bar-8: #045D58;

$color-vannportalen--header-gradient-start: $color-vannportalen--blue-light-4;
$color-vannportalen--header-gradient-stop: #cce6ff;

$color-vannportalen--menu-level-up-button: rgba(0, 73, 144, .1);
$color-vannportalen--menu-level-up-button-hover: rgba(0, 73, 144, .4);

$color--search-hightlight: #ECD898;
