@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media (max-width: 768px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 769px) {
  .u_hidden--desktop {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c_master-nav {
  height: 100%;
  overflow: hidden; }
  @media (min-width: 769px) {
    .c_master-nav {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      margin: 0; } }
  .c_master-nav__bottom-gradient {
    width: 100%;
    display: inline-block;
    height: 45px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #e8f3ff; }
    @media (min-width: 769px) {
      .c_master-nav__bottom-gradient {
        height: 60px; } }
  .c_master-nav--overflowing .c_master-nav__bottom-gradient:before {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS44OSAxMy41Ij48dGl0bGU+aWNvbl9zb3J0X3RhYmxlPC90aXRsZT48cGF0aCBkPSJNMTEuNjcsN2EuNzQuNzQsMCwwLDAtMS4wNiwwTDYuNjksMTAuOTRWLjc1YS43NS43NSwwLDAsMC0xLjUsMFYxMC45NEwxLjI4LDdBLjc0Ljc0LDAsMCwwLC4yMiw3YS43NS43NSwwLDAsMCwwLDEuMDZsNS4xOSw1LjE5YS44Mi44MiwwLDAsMCwuMjUuMTYuNzEuNzEsMCwwLDAsLjI4LjA2LjcyLjcyLDAsMCwwLC4yOS0uMDYuNjcuNjcsMCwwLDAsLjI0LS4xNmw1LjItNS4xOUEuNzUuNzUsMCwwLDAsMTEuNjcsN1oiLz48L3N2Zz4=);
    background-repeat: no-repeat;
    width: 1em;
    height: 1em;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 1em;
    opacity: 0.75; }
    @media (min-width: 769px) {
      .c_master-nav--overflowing .c_master-nav__bottom-gradient:before {
        width: 1.25em;
        height: 1.25em; } }
  .c_master-nav--open .c_master-nav__bottom-gradient:before {
    -webkit-animation: bounce 4s 0s 1 forwards ease;
            animation: bounce 4s 0s 1 forwards ease; }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  80% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.75; }
  100% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
    opacity: 0; } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em); }
  80% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0.75; }
  100% {
    -webkit-transform: translateY(-1em);
            transform: translateY(-1em);
    opacity: 0; } }
  .c_master-nav .bm-overlay {
    background: transparent !important; }
    @media (max-width: 768px) {
      .c_master-nav .bm-overlay {
        top: 0; } }
  @media (max-width: 768px) {
    .c_master-nav .bm-menu-wrap {
      top: 0; } }

.c_master-nav__menu {
  background-color: #e8f3ff;
  box-sizing: border-box;
  font-weight: normal;
  padding: 0 10px 10px 10px; }
  @media (min-width: 769px) {
    .c_master-nav__menu {
      max-width: 550px;
      padding: 0; } }
  .c_master-nav__menu .bm-menu {
    overflow: hidden !important;
    width: 100% !important;
    margin-top: 10px; }
    @media (min-width: 769px) {
      .c_master-nav__menu .bm-menu {
        max-width: 550px;
        padding: 1.3em 2.778em 0 2.778em !important;
        margin-top: 0; } }
    .c_master-nav__menu .bm-menu::-webkit-scrollbar {
      width: 0 !important; }
  .c_master-nav__menu .c_close-button {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    font-smoothing: inherit;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    font-size: 0.845em;
    float: right;
    padding: 0.5em;
    margin-right: 0.25em;
    margin-top: 0.5em; }
    @media (min-width: 769px) {
      .c_master-nav__menu .c_close-button {
        margin-right: -0.5em;
        margin-top: -0.5em; } }
    .c_master-nav__menu .c_close-button .c_close-button__inner-wrapper {
      float: right; }
      .c_master-nav__menu .c_close-button .c_close-button__inner-wrapper .c_close-button__text {
        display: block;
        margin-top: 0.015em;
        float: left; }
      .c_master-nav__menu .c_close-button .c_close-button__inner-wrapper svg {
        height: 0.88em;
        width: 0.88em;
        margin-left: 0.88em; }
    @media (min-width: 769px) {
      .c_master-nav__menu .c_close-button:hover .c_close-button__text {
        text-decoration: underline; } }
    .c_master-nav__menu .c_close-button--focus-to-show {
      opacity: 0;
      border: #3285CE solid 2px;
      pointer-events: none;
      text-decoration: underline;
      position: relative; }
      .c_master-nav__menu .c_close-button--focus-to-show:focus {
        opacity: 1;
        pointer-events: all; }
  .c_master-nav__menu .c_search-field {
    width: 100%;
    margin-bottom: 1em; }
  .c_master-nav__menu ul {
    list-style-type: none;
    padding: 0; }
  .c_master-nav__menu .c_nav-section-list {
    margin: 0; }
    .c_master-nav__menu .c_nav-section-list__level-btns-wrapper {
      width: calc(100% - 7em);
      display: inline-block; }
    .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item {
      padding-bottom: 2em; }
      .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button {
        background: none;
        color: inherit;
        border: none;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        font-smoothing: inherit;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
        background-color: rgba(0, 73, 144, 0.1);
        border-bottom: 1px solid #1E4F71;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
        font-size: 0.79em;
        margin-top: 5px;
        margin-right: 0.7em;
        margin-bottom: 0.35em;
        padding: 0.8em 0.9em 0.61em 0.9em;
        transition: all 0.3s ease; }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button:hover {
            background-color: rgba(0, 73, 144, 0.4); }
            .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button:hover span {
              text-decoration: underline; } }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button {
            margin-top: 0;
            margin-bottom: 0.7em; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button svg {
          height: 6px;
          width: 10px;
          margin-top: 4px;
          padding-right: 10px;
          display: inline-block;
          float: left; }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__level-up-button span {
          display: inline-block; }
      .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title {
        color: #222222;
        display: block;
        font-size: 1em;
        border-bottom: 1px solid #82888B;
        margin: 0;
        padding: 0.7em 10px;
        text-decoration: none;
        outline: none;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title {
            padding: 0.7em 0;
            font-size: 1.222em; } }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title:hover {
            text-decoration: underline; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title .c_section-list-item-title__text {
          display: inline-block;
          width: 85%; }
        .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title .c_section-list-item-title__label {
          width: 15%;
          font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
          font-size: 0.833em;
          float: right;
          text-align: right; }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-section-list__item .c_section-list-item__title .c_section-list-item-title__label {
              font-size: 0.7em;
              line-height: 2em; } }
    .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level {
      margin-top: 1em; }
      .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_section-list-item__title {
        margin-top: 1.5em; }
      .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item a {
        font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
        padding: 0.83em 0 0.95em 0; }
        @media (max-width: 768px) {
          .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item a {
            font-size: 0.889em; } }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list.c_nav-section-list--sub-level .c_nav-list .c_nav-list__item a {
            padding: 0.91em 0.455em 0.9em 0.455em; } }
    @media (max-width: 768px) {
      .c_master-nav__menu .c_nav-section-list .c_nav-list {
        margin-top: 0; } }
    @media (min-width: 769px) {
      .c_master-nav__menu .c_nav-section-list .c_nav-list--two-column {
        -webkit-column-count: 2;
                column-count: 2;
        grid-column-gap: 3em;
        -webkit-column-gap: 3em;
                column-gap: 3em; } }
    .c_master-nav__menu .c_nav-section-list .c_nav-list--sub-level {
      margin: 0; }
    .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item {
      border-bottom: 1px solid #82888B;
      padding: 0 0 0 10px;
      display: inline-block;
      width: 100%;
      box-sizing: border-box; }
      @media (min-width: 769px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item {
          padding: 0;
          font-size: 1.222em; } }
      @media (min-width: 769px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item > div {
          margin-left: -0.455em;
          margin-right: -0.455em; } }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button {
        background: none;
        color: inherit;
        border: none;
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        font-smoothing: inherit;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content {
        width: 100%;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
        font-size: 1em;
        text-align: left;
        padding: 0.98em 0 1.05em 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1;
        box-sizing: border-box; }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content {
            padding: 0.8em 0.455em 0.78em 0; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button:focus span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content:focus span {
          text-decoration: underline; }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button:hover span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content:hover span {
            text-decoration: underline; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button--active, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content--active {
          padding: 0.83em 0 0.95em 0; }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button--active:hover span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content--active:hover span {
              text-decoration: none; } }
          @media (max-width: 768px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button--active, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content--active {
              font-size: 0.889em; } }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button--active, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content--active {
              padding: 0.91em 0.455em 0.9em 0.455em; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content span {
          width: 87%;
          display: inline-block;
          flex: 0 1 auto;
          margin-top: 0.2em;
          line-height: 1.2; }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content span {
              width: 90%;
              line-height: 1; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__icon {
          height: 0.9em;
          width: 1.1em;
          float: left;
          flex: 0 0 auto;
          margin-top: -0.1em;
          margin-right: 0.35em;
          margin-left: 0; }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__icon {
              margin-left: 0;
              margin-right: 0.6em;
              margin-top: 0; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__arrow-right, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__arrow-right {
          height: 0.7em;
          width: 0.385em;
          float: right;
          flex: 0 0 auto;
          margin-top: -0.1em;
          margin-right: 0.3em; }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__arrow-right, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__arrow-right {
              margin-right: 0; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__redirect-icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__redirect-icon {
          height: 0.7em;
          width: 0.7em;
          float: right;
          flex: 0 0 auto;
          margin-top: -0.1em;
          margin-right: 0.3em; }
          @media (min-width: 769px) {
            .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button .c_nav-list-item__redirect-icon, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list__item-content .c_nav-list-item__redirect-icon {
              margin-right: 0; } }
      @media (min-width: 769px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item button.c_nav-list__item-content:hover span, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item a.c_nav-list__item-content:hover span {
          text-decoration: underline; } }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item a, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item link, .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item a:visited {
        color: #222222;
        text-decoration: none; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--two-column {
        font-size: 0.889em;
        display: block;
        border-bottom: 1px solid #82888B; }
        @media (min-width: 769px) {
          .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--two-column {
            column-break-inside: avoid;
            -webkit-column-break-inside: avoid; } }
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--two-column button .c_nav-list-item__arrow-right {
          height: 0.55em; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--sub-level {
        font-size: 1em;
        border-bottom-color: #82888B; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--level-block1:first-of-type {
        border-top: 1px solid #82888B;
        padding-top: 0;
        margin-top: 1em; }
      @media (min-width: 769px) {
        .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--level-block3 span {
          font-size: 0.777em; } }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--level-block3 .c_nav-list__item-content {
        padding-top: 0.77em;
        padding-bottom: 0.77em;
        font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item--level-block3 .c_nav-list-item__arrow-right {
        height: 0.5em !important;
        width: 0.3em !important; }
      .c_master-nav__menu .c_nav-section-list .c_nav-list .c_nav-list__item .c_nav-list-item__warning-icon {
        width: 1.7em;
        height: 1.7em;
        margin-right: 0.7em; }
    .c_master-nav__menu .c_nav-section-list .c_nav-list li[class="c_nav-list__item c_nav-list__item--level-block2"],
    .c_master-nav__menu .c_nav-section-list .c_nav-list li[class="c_nav-list__item c_nav-list__item--level-block3"],
    .c_master-nav__menu .c_nav-section-list .c_nav-list li[class="c_nav-list__item c_nav-list__item--level-block4"] {
      margin-top: 2em;
      border-top: 1px solid #82888B; }
    .c_master-nav__menu .c_nav-section-list .c_nav-list li[class="c_nav-list__item c_nav-list__item--level-block2"] ~ li[class="c_nav-list__item c_nav-list__item--level-block2"],
    .c_master-nav__menu .c_nav-section-list .c_nav-list li[class="c_nav-list__item c_nav-list__item--level-block3"] ~ li[class="c_nav-list__item c_nav-list__item--level-block3"],
    .c_master-nav__menu .c_nav-section-list .c_nav-list li[class="c_nav-list__item c_nav-list__item--level-block4"] ~ li[class="c_nav-list__item c_nav-list__item--level-block4"] {
      margin-top: 0;
      border-top: none; }

.c_master-nav--open .c_master-nav__menu .bm-menu {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important; }

@media (max-width: 768px) {
  body.u_master-nav-menu-open {
    overflow: hidden;
    position: fixed;
    top: 0; }
    body.u_master-nav-menu-open .c_master-header {
      overflow: hidden; } }

/* IE9 fix */
@media (min-width: 769px) {
  body.u_master-nav-menu-open .c_master-nav {
    opacity: 1; } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media (max-width: 768px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 769px) {
  .u_hidden--desktop {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.c_header-breadcrumbs {
  display: flex;
  width: 100%;
  color: #1c4264;
  z-index: 1;
  border-bottom: 1px solid #d6e1e8;
  padding: 1.5em 0; }
  .c_header-breadcrumbs a, .c_header-breadcrumbs link, .c_header-breadcrumbs link:visited {
    color: #1c4264; }
  .c_header-breadcrumbs ul {
    padding: 0;
    margin: 0; }
    .c_header-breadcrumbs ul .c_header-breadcrumbs__item {
      display: flex;
      float: left;
      margin-left: 1em;
      line-height: 1.35;
      font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
      align-items: center; }
      .c_header-breadcrumbs ul .c_header-breadcrumbs__item:first-of-type {
        margin: 0; }
      .c_header-breadcrumbs ul .c_header-breadcrumbs__item:last-of-type {
        color: #4F4F4F; }
      @media (min-width: 769px) {
        .c_header-breadcrumbs ul .c_header-breadcrumbs__item a:hover {
          color: #1c4264;
          text-decoration: none; } }
      .c_header-breadcrumbs ul .c_header-breadcrumbs__item svg {
        opacity: 0.5;
        width: 0.3em;
        height: 1em;
        margin-left: 1em; }
  .c_header-breadcrumbs .c_header-breadcrumbs__item {
    display: inline-block; }
  .c_header-breadcrumbs svg {
    opacity: 0.5;
    width: 0.3em;
    height: 0.4em;
    margin-left: 0.4em; }

.c_loading-spinner {
  margin: 4em auto; }
  .c_loading-spinner svg {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    -webkit-animation: spinAnimation 1s linear infinite;
            animation: spinAnimation 1s linear infinite; }

@-webkit-keyframes spinAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinAnimation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.c_search-field {
  position: relative;
  font-size: 1em;
  width: 5.5em;
  transition: all 0.3s ease;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }
  @media (min-width: 769px) {
    .c_search-field {
      margin-right: 10px; } }
  .c_search-field--english {
    width: 7.5em; }
  .c_search-field--focused {
    z-index: 2; }
    @media (min-width: 769px) {
      .c_search-field--focused {
        width: calc(100% - 7.75em);
        border: none; } }
    .c_search-field--focused button, .c_search-field--focused a {
      pointer-events: auto !important;
      cursor: pointer !important; }
  .c_search-field--full-width {
    width: 100%;
    margin: 0 auto 2em auto; }
    .c_search-field--full-width .c_search-field__input:focus {
      background-color: #ECF2F7 !important; }
  .c_search-field button, .c_search-field a {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    font-smoothing: inherit;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    margin: 0;
    padding: 0;
    pointer-events: none;
    height: auto;
    width: auto;
    position: absolute; }
    .c_search-field button.c_search-field__search-button, .c_search-field a.c_search-field__search-button {
      top: 59%;
      left: 1.2em;
      -webkit-transform: translateY(-59%);
              transform: translateY(-59%); }
      .c_search-field button.c_search-field__search-button .c_search-field__icon, .c_search-field a.c_search-field__search-button .c_search-field__icon {
        height: 2.2em;
        width: 2.4em; }
    .c_search-field button.c_search-field__clear-button, .c_search-field a.c_search-field__clear-button {
      width: 44px;
      height: 44px;
      top: 55%;
      right: 0.7em;
      -webkit-transform: translateY(-52%);
              transform: translateY(-52%); }
      .c_search-field button.c_search-field__clear-button .c_search-field__icon, .c_search-field a.c_search-field__clear-button .c_search-field__icon {
        height: 1em;
        width: 1em; }
  .c_search-field__input {
    box-sizing: border-box;
    box-shadow: none;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-size: 1em;
    outline: none;
    padding: 1.02em 1.1em calc(0.85em - 2px) 2.7em;
    width: 100%;
    transition: all 0.3s ease;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    background-color: #fefefe;
    border: 2px solid #004990;
    border-top-color: transparent;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    transition: all 0.3s ease;
    -webkit-transform-origin: right center;
            transform-origin: right center; }
    @media (min-width: 769px) {
      .c_search-field__input {
        background-color: #edf6ff;
        min-height: 2.944em; } }
    @media (min-width: 769px) {
      .c_search-field--focused .c_search-field__input {
        width: 100%; } }
    .c_search-field__input::-webkit-search-decoration, .c_search-field__input::-webkit-search-cancel-button, .c_search-field__input::-webkit-search-results-button, .c_search-field__input::-webkit-search-results-decoration {
      display: none; }
    .c_search-field__input::-webkit-input-placeholder {
      color: #19384E;
      opacity: 1; }
    .c_search-field__input::placeholder {
      color: #19384E;
      opacity: 1; }
    .c_search-field__input:focus {
      /* FF 4-18 */
      /* FF 19+ */
      /* IE 10+ */ }
      @media (min-width: 769px) {
        .c_search-field__input:focus {
          background-color: #edf6ff;
          outline: none; } }
      .c_search-field__input:focus::-webkit-input-placeholder {
        color: transparent; }
      .c_search-field__input:focus:-moz-placeholder {
        color: transparent; }
      .c_search-field__input:focus::-moz-placeholder {
        color: transparent; }
      .c_search-field__input:focus:-ms-input-placeholder {
        color: transparent; }
    @media (min-width: 769px) {
      .c_search-field__input:hover {
        background-color: #edf6ff; }
        .c_search-field__input:hover::-webkit-input-placeholder {
          text-decoration: underline; }
        .c_search-field__input:hover::placeholder {
          text-decoration: underline; } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.g_row {
  display: block;
  width: 100%;
  overflow: hidden; }
  .g_row--accept-overflow {
    overflow: visible;
    clear: both; }

.g_container {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1090px;
  overflow: visible;
  padding: 0 10px;
  position: relative; }
  @media (min-width: 768px) {
    .g_container\@tablet-and-desktop {
      box-sizing: border-box;
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 1090px;
      overflow: visible;
      padding: 0 10px;
      position: relative; } }
  .g_container--small {
    max-width: 700px; }
    @media (min-width: 1024px) {
      .g_container--small {
        width: 85%; } }
    .g_container--small .g_container--medium {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding-left: 10px;
      padding-right: 10px; }
      @media (min-width: 768px) {
        .g_container--small .g_container--medium {
          width: 800px;
          margin-left: calc(800px / -2);
          margin-right: calc(800px / -2); } }
    .g_container--small .g_container--full, .g_container--small .g_container--max {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding-left: 10px;
      padding-right: 10px; }
      @media (min-width: 1090px) {
        .g_container--small .g_container--full, .g_container--small .g_container--max {
          width: 1090px;
          margin-left: calc(1090px / -2);
          margin-right: calc(1090px / -2); } }
      @media (min-width: 700px) {
        .g_container--small .g_container--full\@tablet-and-desktop, .g_container--small .g_container--max\@tablet-and-desktop {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          padding-left: 10px;
          padding-right: 10px; } }
  @media (min-width: 700px) and (min-width: 1090px) {
    .g_container--small .g_container--full\@tablet-and-desktop, .g_container--small .g_container--max\@tablet-and-desktop {
      width: 1090px;
      margin-left: calc(1090px / -2);
      margin-right: calc(1090px / -2); } }
  .g_container--medium {
    max-width: 800px; }
    @media (min-width: 1024px) {
      .g_container--medium {
        width: 85%; } }
  .g_container--full {
    max-width: 1090px; }
    @media (min-width: 1024px) {
      .g_container--full {
        width: 85%; } }
    @media (max-width: 768px) {
      .g_container--full .g_container--small {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 768px) {
      .g_container--full\@tablet-and-desktop {
        width: 85%;
        position: relative;
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px; } }

.g_col {
  display: block;
  width: 100%;
  min-height: 1px; }
  @media (min-width: 769px) {
    .g_col {
      box-sizing: border-box;
      float: left;
      padding-right: 3%; }
      .g_col:last-of-type {
        padding-right: 0; }
      .g_col.g_col--is-last {
        padding: 0;
        float: none; }
      .g_col.g_col--large-1 {
        width: 8.33333333333333%; }
      .g_col.g_col--large-2 {
        width: 16.66666666666667%; }
      .g_col.g_col--large-3 {
        width: 25%; }
      .g_col.g_col--large-4 {
        width: 33.33333333333333%; }
      .g_col.g_col--large-5 {
        width: 41.66666666666667%; }
      .g_col.g_col--large-6 {
        width: 50%; }
      .g_col.g_col--large-7 {
        width: 58.333333333333336%; }
      .g_col.g_col--large-8 {
        width: 66.66666666666667%; }
      .g_col.g_col--large-9 {
        width: 75%; }
      .g_col.g_col--large-10 {
        width: 83.33333333333334%; }
      .g_col.g_col--large-11 {
        width: 91.66666666666666%; }
      .g_col.g_col--large-12 {
        width: 100%; } }
  .g_col > .g_container {
    padding: 0; }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@media (max-width: 768px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 769px) {
  .u_hidden--desktop {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.c_master-header {
  background-color: transparent;
  width: 100%;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  position: relative; }
  @media (max-width: 768px) {
    .c_master-header {
      overflow: hidden; } }
  @media (min-width: 769px) {
    .c_master-header {
      box-shadow: none;
      padding-bottom: 2em; }
      .c_master-header.c_master-header--vannportalen-frontpage {
        background: transparent linear-gradient(180deg, #e5f2ff 0%, #cce6ff 100%) 0% 0% no-repeat padding-box;
        margin-bottom: 2.4em; } }
  .c_master-header .c_master-header__home-link {
    display: block;
    overflow: auto;
    float: left;
    text-decoration: none; }
    @media (min-width: 769px) {
      .c_master-header .c_master-header__home-link {
        margin-right: 23px; } }
    .c_master-header .c_master-header__home-link--search-active {
      width: 7em;
      overflow-x: hidden; }
  .c_master-header .c_master-header__logo {
    width: 9em;
    height: 2em;
    margin: 1em 0 0.5em 0; }
    .c_master-header .c_master-header__logo--mobile {
      width: 11em;
      height: 4em;
      margin: 0; }
      @media (min-width: 769px) {
        .c_master-header .c_master-header__logo--mobile {
          display: none; } }
    .c_master-header .c_master-header__logo--desktop {
      display: none;
      /* height: 127px;
			width: 127px; */
      height: 125px;
      width: 400px;
      margin: 0; }
      @media (min-width: 769px) {
        .c_master-header .c_master-header__logo--desktop {
          display: block; } }
  .c_master-header .c_master-header__content-wrapper {
    display: block;
    width: auto;
    height: 4em;
    overflow: hidden; }
    @media (min-width: 769px) {
      .c_master-header .c_master-header__content-wrapper {
        height: 6.444em; } }
    @media print {
      .c_master-header .c_master-header__content-wrapper {
        display: none; } }
  .c_master-header .c_master-header__actions-wrapper {
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 769px) {
      .c_master-header .c_master-header__actions-wrapper {
        position: absolute;
        bottom: 1em;
        right: 10px;
        width: calc(100% - 160px); } }
  .c_master-header .c_search-field-suggestions {
    width: calc(100% - 7.7em);
    box-sizing: border-box;
    background-color: #fefefe;
    border: 1px solid #8097A8;
    position: absolute;
    top: 3.1em;
    left: 0.45em;
    z-index: 666; }
    .c_master-header .c_search-field-suggestions__list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      background-color: #ffffff; }
    .c_master-header .c_search-field-suggestions__list-item {
      margin: 0;
      overflow: auto; }
      .c_master-header .c_search-field-suggestions__list-item a {
        display: block;
        overflow: auto;
        width: auto;
        color: #222222;
        background-color: #ffffff;
        margin: 0;
        text-align: left;
        font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
        font-size: 1em;
        padding: 1em;
        transition: all 0.25s ease; }
        @media (min-width: 769px) {
          .c_master-header .c_search-field-suggestions__list-item a:hover {
            background-color: rgba(236, 242, 247, 0.55); }
            .c_master-header .c_search-field-suggestions__list-item a:hover span {
              text-decoration: underline; } }
        .c_master-header .c_search-field-suggestions__list-item a:focus {
          background-color: rgba(255, 255, 0, 0.2); }
        .c_master-header .c_search-field-suggestions__list-item a span {
          display: block; }
        .c_master-header .c_search-field-suggestions__list-item a .name {
          float: left;
          text-decoration: underline; }
        .c_master-header .c_search-field-suggestions__list-item a .hit-category {
          color: #777777;
          float: right; }
    .c_master-header .c_search-field-suggestions .c_loading-spinner {
      margin: 1.6em auto; }
      .c_master-header .c_search-field-suggestions .c_loading-spinner svg {
        width: 2.5em;
        height: 2.5em; }

.c_nav-toggle-button {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  font-smoothing: inherit;
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
  border-left: 1px solid #f0f0f0;
  cursor: pointer;
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-size: 1em;
  outline: none;
  padding: 1.65em 1.85em 1.1em 1.4em;
  position: absolute;
  right: -10px;
  bottom: 0;
  max-height: 4em;
  margin: 0;
  box-sizing: border-box;
  transition: hover 0.3s ease, outline 0.1s ease; }
  @media (min-width: 769px) {
    .c_nav-toggle-button {
      background-color: #004990;
      border: none;
      color: #ffffff;
      padding: 1.1em 1.1em 0.85em 1.1em;
      position: relative;
      right: 0;
      min-height: 2.944em; } }
  @media screen and (min-width: 769px) and (-ms-high-contrast: active), screen and (min-width: 769px) and (-ms-high-contrast: none) {
    .c_nav-toggle-button {
      /* IE10+ specific styles go here */
      height: 58px; } }
  @media (min-width: 769px) {
    .c_nav-toggle-button:hover {
      background-color: #19384E; }
      .c_nav-toggle-button:hover .c_nav-toggle-button__text {
        text-decoration: underline; } }
  .c_nav-toggle-button:focus {
    outline: #BD6B00 solid 2px; }
    @media (min-width: 769px) {
      .c_nav-toggle-button:focus {
        background-color: #19384E; } }
  .c_nav-toggle-button .c_nav-toggle-button__text {
    margin-right: 0.833em; }
  .c_nav-toggle-button .c_nav-toggle-button__icon {
    width: 1.111em;
    height: 0.97em;
    margin-bottom: -0.1em; }
    .c_nav-toggle-button .c_nav-toggle-button__icon--close-menu {
      width: 0.889em; }

.c_mobile-header-search-button {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  font-smoothing: inherit;
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
  background: #ffffff;
  border-left: 1px solid #f0f0f0;
  font-size: 1em;
  padding: 1.5em 0.889em 0.8em 1.222em;
  position: absolute;
  right: 4.167em;
  bottom: 0;
  max-height: 4em;
  margin: 0;
  box-sizing: border-box; }
  .c_mobile-header-search-button svg {
    width: 1.4em;
    height: 1.3em; }

.c_mobile-search-panel {
  background-color: #5d92c6;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
  -webkit-animation: slidePanelIn 0.4s ease-out 1 forwards;
          animation: slidePanelIn 0.4s ease-out 1 forwards; }
  .c_mobile-search-panel--close {
    -webkit-animation: slidePanelOut 0.4s ease-out 1 forwards;
            animation: slidePanelOut 0.4s ease-out 1 forwards; }
  .c_mobile-search-panel .c_close-button {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    font-smoothing: inherit;
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important;
    font-size: 0.845em;
    padding: 0.5em;
    position: absolute;
    right: 0.6em;
    top: 1em; }
    .c_mobile-search-panel .c_close-button .c_close-button__inner-wrapper {
      float: right; }
      .c_mobile-search-panel .c_close-button .c_close-button__inner-wrapper .c_close-button__text {
        display: inline-block;
        margin-top: -0.12em; }
      .c_mobile-search-panel .c_close-button .c_close-button__inner-wrapper svg {
        height: 0.88em;
        width: 0.88em;
        margin-left: 0.88em; }
  .c_mobile-search-panel .c_search-field {
    position: absolute;
    width: 93%;
    left: 0;
    right: 0;
    top: 3.5em; }
    .c_mobile-search-panel .c_search-field__input:focus {
      background-color: #ffffff !important; }
  .c_mobile-search-panel .c_search-field-suggestions {
    top: 6.6em;
    left: 0;
    right: 0;
    width: 93%;
    margin: auto; }
    .c_mobile-search-panel .c_search-field-suggestions__list-item {
      border-bottom: 1px solid #cccccc; }
      .c_mobile-search-panel .c_search-field-suggestions__list-item:last-of-type {
        border: none; }
      .c_mobile-search-panel .c_search-field-suggestions__list-item .name {
        width: 56%;
        margin-right: 4%; }
      .c_mobile-search-panel .c_search-field-suggestions__list-item .hit-category {
        width: 40%;
        text-align: right; }

@-webkit-keyframes slidePanelIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slidePanelIn {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slidePanelOut {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes slidePanelOut {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  to {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

/* stylelint-disable at-rule-no-unknown */
@-webkit-keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-size: 1.125em;
  color: #222222; }

p, li {
  font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  line-height: 1.5; }

p {
  margin-top: 0.5em;
  margin-bottom: 0.85em; }

a {
  color: #004990; }
  @media (min-width: 769px) {
    a:hover {
      color: #222222; } }

h1 {
  font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-size: 1.778em;
  font-weight: normal;
  line-height: 1.25; }
  @media (min-width: 769px) {
    h1 {
      font-size: 2.111em; } }
  h1 strong {
    font-family: "PalatinoLTStd-Light", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-weight: normal; }
  h1:focus {
    outline: none; }

h2 {
  font-size: 1.444em;
  line-height: 1.3529411764705883;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }
  @media (min-width: 769px) {
    h2 {
      font-size: 1.778em; } }
  h2 strong {
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-weight: normal; }

h3 {
  font-size: 1.333em;
  line-height: 1.4285714285714286; }
  @media (min-width: 769px) {
    h3 {
      font-size: 1.444em; } }
  h3 strong {
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-weight: normal; }

h4 {
  font-size: 1.222em;
  line-height: 1.25; }
  h4 strong {
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-weight: normal; }

h5 {
  font-size: 1.222em;
  line-height: 1.3636363636363635; }

h6 {
  font-size: 1.111em;
  line-height: 1.3636363636363635; }

h2, h3, h4, h5, h6 {
  font-weight: normal; }

h3, h4, h5, h6 {
  margin-top: 1.2em;
  margin-bottom: -0.2em; }

strong, b {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-weight: normal; }

em, i {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
  font-weight: normal; }
  em strong, em b, i strong, i b {
    font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif;
    font-weight: normal; }

.\--preamble {
  font-size: 1.222em;
  font-weight: normal;
  line-height: 1.3636363636363635; }

.\--small {
  font-size: 0.889em; }

.mdir_has-glossary {
  border-bottom: 2px dashed #009b96;
  cursor: help; }

a .mdir_has-glossary, button .mdir_has-glossary, h1 .mdir_has-glossary, h2 .mdir_has-glossary, h3 .mdir_has-glossary, h4 .mdir_has-glossary, h5 .mdir_has-glossary, label .mdir_has-glossary {
  border-bottom: none;
  cursor: default; }

.c_xhtml-editor-block ul:not([class]),
.t_subject-page ul:not([class]),
.t_env-indicator-page ul:not([class]),
.t_env-result-area-page ul:not([class]),
.t_env-target-page ul:not([class]) {
  padding-left: 0;
  list-style: none; }
  .c_xhtml-editor-block ul:not([class]) li,
  .t_subject-page ul:not([class]) li,
  .t_env-indicator-page ul:not([class]) li,
  .t_env-result-area-page ul:not([class]) li,
  .t_env-target-page ul:not([class]) li {
    line-height: 1.3;
    padding-left: 1.5em;
    margin-top: 0.35em;
    margin-bottom: 0.35em;
    position: relative; }
    .c_xhtml-editor-block ul:not([class]) li:after,
    .t_subject-page ul:not([class]) li:after,
    .t_env-indicator-page ul:not([class]) li:after,
    .t_env-result-area-page ul:not([class]) li:after,
    .t_env-target-page ul:not([class]) li:after {
      content: '';
      height: 0.33em;
      width: 0.33em;
      background: #222222;
      display: block;
      position: absolute;
      top: 0.3em;
      left: 0;
      border-radius: 50%; }
  .c_xhtml-editor-block ul:not([class]) ul,
  .t_subject-page ul:not([class]) ul,
  .t_env-indicator-page ul:not([class]) ul,
  .t_env-result-area-page ul:not([class]) ul,
  .t_env-target-page ul:not([class]) ul {
    padding-left: 0;
    margin-bottom: 0.45em;
    margin-top: 0.25em; }
    .c_xhtml-editor-block ul:not([class]) ul li:after,
    .t_subject-page ul:not([class]) ul li:after,
    .t_env-indicator-page ul:not([class]) ul li:after,
    .t_env-result-area-page ul:not([class]) ul li:after,
    .t_env-target-page ul:not([class]) ul li:after {
      height: 0.27em;
      width: 0.27em;
      background: transparent;
      border: 1px solid #222222; }
    .c_xhtml-editor-block ul:not([class]) ul ul,
    .t_subject-page ul:not([class]) ul ul,
    .t_env-indicator-page ul:not([class]) ul ul,
    .t_env-result-area-page ul:not([class]) ul ul,
    .t_env-target-page ul:not([class]) ul ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .c_xhtml-editor-block ul:not([class]) ul ul li,
      .t_subject-page ul:not([class]) ul ul li,
      .t_env-indicator-page ul:not([class]) ul ul li,
      .t_env-result-area-page ul:not([class]) ul ul li,
      .t_env-target-page ul:not([class]) ul ul li {
        margin: 0.25em 0;
        padding-left: 1.5em;
        position: relative; }
        .c_xhtml-editor-block ul:not([class]) ul ul li:after,
        .t_subject-page ul:not([class]) ul ul li:after,
        .t_env-indicator-page ul:not([class]) ul ul li:after,
        .t_env-result-area-page ul:not([class]) ul ul li:after,
        .t_env-target-page ul:not([class]) ul ul li:after {
          height: 0.29em;
          width: 0.29em;
          background: #222222;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          border: none;
          border-radius: 0;
          top: 0.35em; }

.c_xhtml-editor-block ol:not([class]),
.t_subject-page ol:not([class]),
.t_env-indicator-page ol:not([class]),
.t_env-result-area-page ol:not([class]),
.t_env-target-page ol:not([class]) {
  padding-left: 1em;
  list-style: decimal; }
  .c_xhtml-editor-block ol:not([class]) li,
  .t_subject-page ol:not([class]) li,
  .t_env-indicator-page ol:not([class]) li,
  .t_env-result-area-page ol:not([class]) li,
  .t_env-target-page ol:not([class]) li {
    padding-left: 0.5em;
    line-height: 1.3;
    margin-top: 0.35em;
    margin-bottom: 0.35em; }
  .c_xhtml-editor-block ol:not([class]) ol,
  .t_subject-page ol:not([class]) ol,
  .t_env-indicator-page ol:not([class]) ol,
  .t_env-result-area-page ol:not([class]) ol,
  .t_env-target-page ol:not([class]) ol {
    list-style: lower-alpha;
    margin-bottom: 0.45em;
    margin-top: 0.25em; }
    .c_xhtml-editor-block ol:not([class]) ol ol,
    .t_subject-page ol:not([class]) ol ol,
    .t_env-indicator-page ol:not([class]) ol ol,
    .t_env-result-area-page ol:not([class]) ol ol,
    .t_env-target-page ol:not([class]) ol ol {
      list-style: lower-roman;
      margin: 0; }

.c_xhtml-editor-block table:not([class]),
.t_subject-page table:not([class]),
.t_env-indicator-page table:not([class]),
.t_env-result-area-page table:not([class]),
.t_env-target-page table:not([class]) {
  font-family: 'Open Sans', Verdana, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.889em;
  margin: 1em 0 1.75em 0;
  border-collapse: collapse; }
  .c_xhtml-editor-block table:not([class]) th,
  .t_subject-page table:not([class]) th,
  .t_env-indicator-page table:not([class]) th,
  .t_env-result-area-page table:not([class]) th,
  .t_env-target-page table:not([class]) th {
    font-family: 'Open Sans', Verdana, Arial, sans-serif;
    font-weight: 700; }
  .c_xhtml-editor-block table:not([class]) td, .c_xhtml-editor-block table:not([class]) th,
  .t_subject-page table:not([class]) td,
  .t_subject-page table:not([class]) th,
  .t_env-indicator-page table:not([class]) td,
  .t_env-indicator-page table:not([class]) th,
  .t_env-result-area-page table:not([class]) td,
  .t_env-result-area-page table:not([class]) th,
  .t_env-target-page table:not([class]) td,
  .t_env-target-page table:not([class]) th {
    border-bottom: 1px solid #cccccc;
    padding: 0.5em;
    text-align: left;
    word-break: break-word; }
  .c_xhtml-editor-block table:not([class]) strong,
  .t_subject-page table:not([class]) strong,
  .t_env-indicator-page table:not([class]) strong,
  .t_env-result-area-page table:not([class]) strong,
  .t_env-target-page table:not([class]) strong {
    font-family: 'Open Sans', Verdana, Arial, sans-serif;
    font-weight: 700; }

table th {
  font-family: 'Open Sans', Verdana, Arial, sans-serif;
  font-weight: 700; }

table:not([class]) {
  font-family: 'Open Sans', Verdana, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.889em;
  margin: 2em 0 2.5em 0;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
  table-layout: fixed; }
  table:not([class]) th {
    font-family: 'Open Sans', Verdana, Arial, sans-serif;
    font-weight: 700; }
  table:not([class]) tr {
    border: none;
    padding: 0; }
  table:not([class]) td, table:not([class]) th {
    border: 1px solid #ccc;
    padding: 0.5em 0.5em;
    width: 1px; }
  table:not([class]) strong {
    font-family: 'Open Sans', Verdana, Arial, sans-serif;
    font-weight: 600; }

/* stylelint-disable at-rule-no-unknown */
@keyframes animateAnchorsIn {
  from {
    -webkit-transform: translateY(-3em);
            transform: translateY(-3em); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.g_row {
  display: block;
  width: 100%;
  overflow: hidden; }
  .g_row--accept-overflow {
    overflow: visible;
    clear: both; }

.g_container {
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1090px;
  overflow: visible;
  padding: 0 10px;
  position: relative; }
  @media (min-width: 768px) {
    .g_container\@tablet-and-desktop {
      box-sizing: border-box;
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 1090px;
      overflow: visible;
      padding: 0 10px;
      position: relative; } }
  .g_container--small {
    max-width: 700px; }
    @media (min-width: 1024px) {
      .g_container--small {
        width: 85%; } }
    .g_container--small .g_container--medium {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding-left: 10px;
      padding-right: 10px; }
      @media (min-width: 768px) {
        .g_container--small .g_container--medium {
          width: 800px;
          margin-left: calc(800px / -2);
          margin-right: calc(800px / -2); } }
    .g_container--small .g_container--full, .g_container--small .g_container--max {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding-left: 10px;
      padding-right: 10px; }
      @media (min-width: 1090px) {
        .g_container--small .g_container--full, .g_container--small .g_container--max {
          width: 1090px;
          margin-left: calc(1090px / -2);
          margin-right: calc(1090px / -2); } }
      @media (min-width: 700px) {
        .g_container--small .g_container--full\@tablet-and-desktop, .g_container--small .g_container--max\@tablet-and-desktop {
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          padding-left: 10px;
          padding-right: 10px; } }
  @media (min-width: 700px) and (min-width: 1090px) {
    .g_container--small .g_container--full\@tablet-and-desktop, .g_container--small .g_container--max\@tablet-and-desktop {
      width: 1090px;
      margin-left: calc(1090px / -2);
      margin-right: calc(1090px / -2); } }
  .g_container--medium {
    max-width: 800px; }
    @media (min-width: 1024px) {
      .g_container--medium {
        width: 85%; } }
  .g_container--full {
    max-width: 1090px; }
    @media (min-width: 1024px) {
      .g_container--full {
        width: 85%; } }
    @media (max-width: 768px) {
      .g_container--full .g_container--small {
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 768px) {
      .g_container--full\@tablet-and-desktop {
        width: 85%;
        position: relative;
        margin: 0 auto;
        padding-left: 10px;
        padding-right: 10px; } }

.g_col {
  display: block;
  width: 100%;
  min-height: 1px; }
  @media (min-width: 769px) {
    .g_col {
      box-sizing: border-box;
      float: left;
      padding-right: 3%; }
      .g_col:last-of-type {
        padding-right: 0; }
      .g_col.g_col--is-last {
        padding: 0;
        float: none; }
      .g_col.g_col--large-1 {
        width: 8.33333333333333%; }
      .g_col.g_col--large-2 {
        width: 16.66666666666667%; }
      .g_col.g_col--large-3 {
        width: 25%; }
      .g_col.g_col--large-4 {
        width: 33.33333333333333%; }
      .g_col.g_col--large-5 {
        width: 41.66666666666667%; }
      .g_col.g_col--large-6 {
        width: 50%; }
      .g_col.g_col--large-7 {
        width: 58.333333333333336%; }
      .g_col.g_col--large-8 {
        width: 66.66666666666667%; }
      .g_col.g_col--large-9 {
        width: 75%; }
      .g_col.g_col--large-10 {
        width: 83.33333333333334%; }
      .g_col.g_col--large-11 {
        width: 91.66666666666666%; }
      .g_col.g_col--large-12 {
        width: 100%; } }
  .g_col > .g_container {
    padding: 0; }

@media (max-width: 768px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.u_visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.u_hidden--all {
  display: none; }

@media (min-width: 769px) {
  .u_hidden--desktop {
    display: none !important; } }

@media screen and (max-width: 768px) {
  .u_hidden--mobile {
    display: none !important; } }

.u_font-weight-bold {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-medium {
  font-family: "PalatinoLTStd-Medium", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif !important; }

.u_font-weight-normal {
  font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

.u_font-style-italic {
  font-family: "PalatinoLTStd-Italic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

p.u_font-style-italic {
  font-family: "PalatinoLTStd-LightItalic", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

* {
  outline-offset: 3px; }
  *:focus, *:active {
    outline-color: #BD6B00; }
  *:focus {
    outline-style: solid;
    outline-width: 2px; }

body {
  background-color: #fefefe;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }
  body button {
    font-family: "PalatinoLTStd-Roman", Palatino Linotype, Book Antiqua3, Palatino, Palatino-Roman, serif; }

@media (max-width: 768px) {
  body.u_master-nav-menu-open {
    overflow: hidden; } }

.no-script {
  margin: 0 auto;
  width: 90%; }

.app-wrapper {
  width: 100%;
  height: 100%; }

.skipLink {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  background-color: #ebf3f3;
  color: #222222;
  opacity: 0; }
  .skipLink:focus {
    opacity: 1;
    padding: 0.5em;
    overflow: auto;
    height: auto;
    width: auto;
    margin: 0;
    z-index: 3;
    outline: #BD6B00 solid 2px;
    outline-offset: 3px;
    transition: all 0.4s ease; }
  .skipLink--top {
    top: 0.5em;
    left: 0.5em;
    z-index: 1; }
    .skipLink--top:focus {
      -webkit-animation: slideInSkipLinkTop 0.4s 0s forwards;
              animation: slideInSkipLinkTop 0.4s 0s forwards; }
  .skipLink--bottom {
    bottom: 0.5em;
    right: 0.5em; }
    .skipLink--bottom:focus {
      outline: white solid 2px;
      -webkit-animation: slideInSkipLinkBottom 0.4s 0s forwards;
              animation: slideInSkipLinkBottom 0.4s 0s forwards; }

.c_mstatus-main {
  position: relative;
  z-index: 2;
  -webkit-animation: fadeInMain 0.75s 0s ease forwards;
          animation: fadeInMain 0.75s 0s ease forwards; }

.c_loading-main .c_loading-spinner {
  margin-top: 7.5em;
  margin-bottom: 7.5em; }

.mdir_has-glossary {
  border-bottom: 2px dashed #004990; }

@-webkit-keyframes slideInSkipLinkTop {
  from {
    -webkit-transform: translate3d(-1em, -1em, 0);
            transform: translate3d(-1em, -1em, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInSkipLinkTop {
  from {
    -webkit-transform: translate3d(-1em, -1em, 0);
            transform: translate3d(-1em, -1em, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInSkipLinkBottom {
  from {
    -webkit-transform: translate3d(1em, 1em, 0);
            transform: translate3d(1em, 1em, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInSkipLinkBottom {
  from {
    -webkit-transform: translate3d(1em, 1em, 0);
            transform: translate3d(1em, 1em, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeInMain {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeInMain {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

