@import './_variables.scss';

body.u_master-nav-menu-open {
	@media (max-width: $small-screen-max) {
		overflow: hidden;
	}
}

.u_visuallyhidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px; width: 1px;
	margin: -1px; padding: 0; border: 0;
}

.u_hidden--all {
	display: none;
}

.u_hidden--desktop {
	@media (min-width: $large-screen-min) {
		display: none !important;
	}
}

.u_hidden--mobile {
	@media screen and (max-width: $small-screen-max) {
		display: none !important;
	}
}

.u_font-weight-bold {
	font-family: $medium-font-family !important;
}

.u_font-weight-medium {
	font-family: $medium-font-family !important;
}

.u_font-weight-normal {
	font-family: $base-font-family;
}

.u_font-style-italic {
	font-family: $base-italic-font-family;
}

p.u_font-style-italic {
	font-family: $light-italic-font-family;
}
