@import '../../styles/_variables.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';
@import '../../styles/_colorsVannportalen.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_utilities.scss';

.c_header-breadcrumbs {
	display: flex;
	width: 100%;
	color: $color-vannportalen-link-1;
	z-index: 1;
	border-bottom: 1px solid $color-vannportalen--border-1;
	padding: 1.5em 0;

	a, link, link:visited {
		color: $color-vannportalen-link-1;
	}

	ul {
		padding: 0;
		margin: 0;

		.c_header-breadcrumbs__item {
			display: flex;
			float: left;
			margin-left: 1em;
			line-height: 1.35;
			font-family: $base-font-family;
			align-items: center;

			&:first-of-type {
				margin: 0;
			}

			&:last-of-type {
				color: $color--emperor;
			}

			a {
				@include hover() {
					color: $color-vannportalen-link-1;
					text-decoration: none;
				}
			}

			svg {
				opacity: 0.5;
				width: 0.3em;
    			height: 1em;
				margin-left: 1em;
			}
		}
	}

	.c_header-breadcrumbs__item {
		display: inline-block;
	}

	svg {
		opacity: 0.5;
		width: 0.3em;
		height: 0.4em;
		margin-left: 0.4em;
	}
}