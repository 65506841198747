@import '../../styles/_variables.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';
@import '../../styles/_colorsVannportalen.scss';
@import '../../styles/_mixins.scss';

.c_search-field {
	position: relative;
	font-size: 1em;
	width: 5.5em;
	@include transitionStandard();
	transform-origin: right center;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

	@media (min-width: $large-screen-min) {
		margin-right: $container-padding;
	}

	&--english {
		width: 7.5em;
	}

	&--focused {
		z-index: 2;

		@media (min-width: $large-screen-min) {
			width: calc(100% - 7.75em);
			border: none;
		}

		button, a {
			pointer-events: auto !important;
			cursor: pointer !important;
		}

	}

	&--full-width {
		width: 100%;
		margin: 0 auto 2em auto;

		.c_search-field__input:focus {
			background-color: $color-miljostatus--grey-blue-2 !important;
		}
	}

	button, a {
		@include removeButtonAppearance();
		margin: 0;
		padding: 0;
		pointer-events: none;
		height: auto;
		width: auto;
		position: absolute;

		&.c_search-field__search-button {
			top: 59%;
			left: 1.2em;
			transform: translateY(-59%);

			.c_search-field__icon {
				height: 2.2em;
				width: 2.4em;
			}
		}

		&.c_search-field__clear-button {
			width: 44px;
			height: 44px;
			top: 55%;
			right: 0.7em;
			transform: translateY(-52%);

			.c_search-field__icon {
				height: 1em;
				width: 1em;
			}
		}
	}

	&__input {
		box-sizing: border-box;
		box-shadow: none;
		font: {
			family: $base-font-family;
			size: 1em;
		}
		outline: none;
		padding: 1.02em 1.1em calc(0.85em - 2px) 2.7em;
		width: 100%;
		transition: all 0.3s ease;
		-webkit-appearance: none;
		appearance: none;
		border-radius: 0;
		background-color: $color--background-white;
		border: 2px solid $color-vannportalen--blue-dark;
		border-top-color: transparent;
		border-right: 0px solid transparent;
		border-left: 0px solid transparent;
		@include transitionStandard();
		transform-origin: right center;

		@media (min-width: $large-screen-min) {
			background-color: $color-vannportalen--blue-light-5;
			min-height: 2.944em;
		}

		.c_search-field--focused & {
			@media (min-width: $large-screen-min) {
				width: 100%;
			}
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}

		&::placeholder {
			color: $color-miljostatus--dark-blue-2;
			opacity: 1;
		}

		&:focus {
			@media (min-width: $large-screen-min) {
				background-color: $color-vannportalen--blue-light-5;
				outline: none;
			}

			&::-webkit-input-placeholder { color:transparent; }
			&:-moz-placeholder { color:transparent; } /* FF 4-18 */
			&::-moz-placeholder { color:transparent; } /* FF 19+ */
			&:-ms-input-placeholder { color:transparent; } /* IE 10+ */
		}

		@include hover() {
			background-color: $color-vannportalen--blue-light-5;

			&::placeholder {
				text-decoration: underline;
			}
		}
	}
}
