@import '_variables.scss';
@import '_mixins.scss';
@import '_colors.scss';
@import '_colorsMiljostatus.scss';
@import '_colorsVannportalen.scss';

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font: {
		family: $base-font-family;
		size: $base-font-size;
	}
	color: $color--black;
}

p, li {
	font: {
		family: $light-font-family;
	}
	line-height: 1.5;
}

p {
	margin-top: 0.5em;
	margin-bottom: 0.85em;
}

a {
	color: $color-vannportalen-link;

	@include hover() {
		color: $color--black;
	}
}

h1 {
	font: {
		family: $light-font-family;
		size: 1.778em;
		weight: normal;
	}
	line-height: 1.25;

	@media (min-width: $large-screen-min) {
		font-size: 2.111em;
	}

	strong {
		font: {
			family: $light-font-family;
			weight: normal;
		}
	}

	&:focus {
		outline: none;
	}
}

h2 {
	font-size: 1.444em;
	line-height: 1.3529411764705883;
	margin-top: 1.5em;
	margin-bottom: 0.5em;

	@media (min-width: $large-screen-min) {
		font-size: 1.778em;
	}

	strong {
		font: {
			family: $base-font-family;
			weight: normal;
		}
	}
}

h3 {
	font-size: 1.333em;
	line-height: 1.4285714285714286;

	@media (min-width: $large-screen-min) {
		font-size: 1.444em;
	}

	strong {
		font: {
			family: $base-font-family;
			weight: normal;
		}
	}
}

h4 {
	font-size: 1.222em;
	line-height: 1.25;

	strong {
		font: {
			family: $base-font-family;
			weight: normal;
		}
	}
}

h5 {
	font-size: 1.222em;
	line-height: 1.3636363636363635;
}

h6 {
	font-size: 1.111em;
	line-height: 1.3636363636363635;
}

h2, h3, h4, h5, h6 {
	font: {
		weight: normal;
	}
}

h3, h4, h5, h6 {
	margin-top: 1.2em;
	margin-bottom: -0.2em;
}

strong, b {
	font: {
		family: $medium-font-family;
		weight: normal;
	}
}

em, i {
	font: {
		family: $light-italic-font-family;
		weight: normal;
	}

	strong, b {
		font: {
			family: $base-italic-font-family;
			weight: normal;
		}
	}
}

.--preamble {
	font: {
		size: 1.222em;
		weight: normal;
	}
	line-height: 1.3636363636363635;
}

.--small {
	font-size: 0.889em;
}

.mdir_has-glossary {
	border-bottom: 2px dashed $color--persian;
	cursor: help;
}

a, button, h1, h2, h3, h4, h5, label {
	.mdir_has-glossary {
		border-bottom: none;
		cursor: default;
	}
}

.c_xhtml-editor-block,
.t_subject-page,
.t_env-indicator-page,
.t_env-result-area-page,
.t_env-target-page {
    ul:not([class]) {
        padding-left: 0;
        list-style: none;

        li {
            line-height: 1.3;
            padding-left: 1.5em;
            margin-top: 0.35em;
            margin-bottom: 0.35em;
            position: relative;

            &:after {
                content: '';
                height: 0.33em;
                width: 0.33em;
                background: $color--black;
                display: block;
                position: absolute;
                top: 0.3em;
                left: 0;
                border-radius: 50%;
            }
        }

        ul {
            padding-left: 0;
            margin-bottom: 0.45em;
            margin-top: 0.25em;

            li {
                &:after {
                    height: 0.27em;
                    width: 0.27em;
                    background: transparent;
                    border: 1px solid $color--black;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin: 0.25em 0;
                    padding-left: 1.5em;
                    position: relative;

                    &:after {
                        height: 0.29em;
                        width: 0.29em;
                        background: $color--black;
                        transform: rotate(45deg);
                        border: none;
                        border-radius: 0;
                        top: 0.35em;
                    }
                }
            }
        }
    }

    ol:not([class]) {
        padding-left: 1em;
        list-style: decimal;

        li {
            padding-left: 0.5em;
            line-height: 1.3;
            margin-top: 0.35em;
            margin-bottom: 0.35em;
        }

        ol {
            list-style: lower-alpha;
            margin-bottom: 0.45em;
            margin-top: 0.25em;

            ol {
                list-style: lower-roman;
                margin: 0;
            }
        }
    }

    table:not([class]) {
        font: {
            family: 'Open Sans', Verdana, Arial, sans-serif;
            weight: 400;
            size: 0.889em;
        }

        margin: 1em 0 1.75em 0;
        border-collapse: collapse;

        th {
            font: {
                family: 'Open Sans', Verdana, Arial, sans-serif;
                weight: 700;
            }
        }

        td, th {
            border-bottom: 1px solid $color--pinkish-grey;
            padding: 0.5em;
            text-align: left;
            word-break: break-word;
        }

        strong {
            font: {
                family: 'Open Sans', Verdana, Arial, sans-serif;
                weight: 700;
            }
        }
    }
}

table {
    th {
        font: {
            family: 'Open Sans', Verdana, Arial, sans-serif;
            weight: 700;
        }
    }
}

table:not([class]) {
    font: {
        family: 'Open Sans', Verdana, Arial, sans-serif;
        weight: 400;
        size: 0.889em;
    }

    margin: 2em 0 2.5em 0;
    border-collapse: collapse;
    border: 1px solid #ccc;
    width: 100%;
    table-layout: fixed;

    th {
        font: {
            family: 'Open Sans', Verdana, Arial, sans-serif;
            weight: 700;
        }
    }

    tr {
        border: none;
        padding: 0;
    }

    td, th {
        border: 1px solid #ccc;
        padding: 0.5em 0.5em;
        width: 1px;
    }

    strong {
        font: {
            family: 'Open Sans', Verdana, Arial, sans-serif;
            weight: 600;
        }
    }
}
