@import '../../styles/_variables.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';
@import '../../styles/_colorsVannportalen.scss';
@import '../../styles/_mixins.scss';

.c_master-nav {
	height: 100%;
	overflow: hidden;

	@media (min-width: $large-screen-min) {
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		margin: 0;
	}

	&__bottom-gradient {
		width: 100%;
		display: inline-block;
		height: ($container-padding * 4.5);
		position: absolute;
		bottom: 0;
		left: 0;
		background: $color-vannportalen--blue-light;
		@media (min-width: $large-screen-min) {
			height: ($container-padding * 6);
		}
	}

	&--overflowing .c_master-nav__bottom-gradient {
		&:before {
			content: '';
			background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMS44OSAxMy41Ij48dGl0bGU+aWNvbl9zb3J0X3RhYmxlPC90aXRsZT48cGF0aCBkPSJNMTEuNjcsN2EuNzQuNzQsMCwwLDAtMS4wNiwwTDYuNjksMTAuOTRWLjc1YS43NS43NSwwLDAsMC0xLjUsMFYxMC45NEwxLjI4LDdBLjc0Ljc0LDAsMCwwLC4yMiw3YS43NS43NSwwLDAsMCwwLDEuMDZsNS4xOSw1LjE5YS44Mi44MiwwLDAsMCwuMjUuMTYuNzEuNzEsMCwwLDAsLjI4LjA2LjcyLjcyLDAsMCwwLC4yOS0uMDYuNjcuNjcsMCwwLDAsLjI0LS4xNmw1LjItNS4xOUEuNzUuNzUsMCwwLDAsMTEuNjcsN1oiLz48L3N2Zz4=);
			background-repeat: no-repeat;
			width: 1em;
			height: 1em;
			display: inline-block;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 1em;
			opacity: 0.75;

			@media (min-width: $large-screen-min) {
				width: 1.25em;
				height: 1.25em;
			}
		}
	}

	&--open .c_master-nav__bottom-gradient:before {
		animation: bounce 4s 0s 1 forwards ease;
	}

	@keyframes bounce {
		0% { transform: translateY(0); }
		20% { transform: translateY(-1em); }
		40% { transform: translateY(0); }
		60% { transform: translateY(-1em); }
		80% { transform: translateY(0); opacity: 0.75; }
		100% { transform: translateY(-1em); opacity: 0; }
	}

	.bm-overlay {
		background: transparent !important;
		@media (max-width: $small-screen-max) {
			top: 0;
		}
	}

	.bm-menu-wrap {
		@media (max-width: $small-screen-max) {
			top: 0;
		}
	}
}

.c_master-nav__menu {
	background-color: $color-vannportalen--blue-light;
	box-sizing: border-box;
	font-weight: normal;
	padding: 0 $container-padding $container-padding $container-padding;

	@media (min-width: $large-screen-min) {
		max-width: 550px;
		padding: 0;
	}

	.bm-menu {
		overflow: hidden !important;
		width: 100% !important;
		margin-top: $container-padding;

		@media (min-width: $large-screen-min) {
			max-width: 550px;
			padding: 1.3em 2.778em 0 2.778em !important;
			margin-top: 0;
		}

		&::-webkit-scrollbar { width: 0 !important }
	}

	.c_close-button {
		@include removeButtonAppearance();
		font: {
			family: $light-italic-font-family !important;
			size: 0.845em;
		}
		float: right;
		padding: 0.5em;
		margin-right: 0.25em;
		margin-top: 0.5em;

		@media (min-width: $large-screen-min) {
			margin-right: -0.5em;
			margin-top: -0.5em;
		}

		.c_close-button__inner-wrapper {
			float: right;

			.c_close-button__text {
				display: block;
				margin-top: 0.015em;
				float: left;
			}

			svg {
				height: 0.88em;
				width: 0.88em;
				margin-left: 0.88em;
			}
		}

		@include hover() {
			.c_close-button__text {
				text-decoration: underline;
			}
		}

		&--focus-to-show {
			opacity: 0;
			border: $color--mariner solid $focus-outline-width;
			pointer-events: none;
			text-decoration: underline;
			position: relative;

			&:focus {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	.c_search-field {
		width: 100%;
		margin-bottom: 1em;
	}

	ul {
		list-style-type: none;
		padding: 0;
	}

	.c_nav-section-list {
		margin: 0;

		&__level-btns-wrapper {
			width: calc(100% - 7em);
			display: inline-block;
		}

		.c_nav-section-list__item {
			padding-bottom: 2em;

			.c_section-list-item__level-up-button {
				@include removeButtonAppearance();
				background-color: $color-vannportalen--menu-level-up-button;
				border-bottom: 1px solid $color-miljostatus--dark-blue-1;
				font: {
					family: $base-font-family;
					size: 0.79em;
				}
				margin-top: 5px;
				margin-right: 0.7em;
				margin-bottom: 0.35em;
				padding: 0.8em 0.9em 0.61em 0.9em;
				@include transitionStandard();

				@include hover() {
					background-color: $color-vannportalen--menu-level-up-button-hover;

					span {
						text-decoration: underline;
					}
				}

				@media (min-width: $large-screen-min) {
					margin-top: 0;
					margin-bottom: 0.7em;
				}

				svg {
					height: 6px;
					width: 10px;
					margin-top: 4px;
					padding-right: 10px;
					display: inline-block;
					float: left;
				}

				span {
					display: inline-block;
				}
			}

			.c_section-list-item {
				&__title {
					color: $color--black;
					display: block;
					font-size: 1em;
					border-bottom: 1px solid $color-miljostatus--menu-border-grey;
					margin: 0;
					padding: 0.7em $container-padding;
					text-decoration: none;
					outline: none;
					font-family: $base-font-family;

					@media (min-width: $large-screen-min) {
						padding: 0.7em 0;
						font-size: 1.222em;
					}

					@include hover() {
						text-decoration: underline;
					}

					.c_section-list-item-title__text {
						display: inline-block;
						width: 85%;
					}

					.c_section-list-item-title__label {
						width: 15%;
						font: {
							family: $light-italic-font-family;
							size: 0.833em;
						}
						float: right;
						text-align: right;

						@media (min-width: $large-screen-min) {
							font-size: 0.7em;
							line-height: 2em;
						}
					}
				}
			}
		}

		&.c_nav-section-list--sub-level {
			margin-top: 1em;

			.c_section-list-item__title {
				margin-top: 1.5em;
			}

			.c_nav-list .c_nav-list__item {
				button, a {
					font-family: $light-font-family !important;
					padding: 0.83em 0 0.95em 0;

					@media (max-width: $small-screen-max) {
						font-size: 0.889em;
					}

					@media (min-width: $large-screen-min) {
						padding: 0.91em 0.455em 0.9em 0.455em;
					}
				}
			}
		}

		.c_nav-list {
			@media (max-width: $small-screen-max) {
				margin-top: 0;
			}

			@media (min-width: $large-screen-min) {
				&--two-column {
					column-count: 2;
					column-gap: 3em;
				}
			}

			&--sub-level {
				margin: 0;
			}

			.c_nav-list__item {
				border-bottom: 1px solid $color-miljostatus--menu-border-grey;
				padding: 0 0 0 $container-padding;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;

				@media (min-width: $large-screen-min) {
					padding: 0;
					font-size: 1.222em;
				}

				> div {
					@media (min-width: $large-screen-min) {
						margin-left: -0.455em;
						margin-right: -0.455em;
					}
				}

				button {
					@include removeButtonAppearance();
				}

				button, .c_nav-list__item-content {
					width: 100%;
					font: {
						family: $base-font-family;
						size: 1em;
					}
					text-align: left;
					padding: 0.98em 0 1.05em 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: 1;
					box-sizing: border-box;

					@media (min-width: $large-screen-min) {
						padding: 0.8em 0.455em 0.78em 0;
					}

					&:focus {
						span {
							text-decoration: underline;
						}
					}

					@include hover() {
						span {
							text-decoration: underline;
						}
					}

					&--active {
						@include hover() {
							span {
								text-decoration: none;
							}
						}
						padding: 0.83em 0 0.95em 0;

						@media (max-width: $small-screen-max) {
							font-size: 0.889em;
						}

						@media (min-width: $large-screen-min) {
							padding: 0.91em 0.455em 0.9em 0.455em;
						}
					}

					span {
						width: 87%;
						display: inline-block;
						flex: 0 1 auto;
						margin-top: 0.2em;
						line-height: 1.2;

						@media (min-width: $large-screen-min) {
							width: 90%;
							line-height: 1;
						}
					}

					.c_nav-list-item__icon {
						height: 0.9em;
						width: 1.1em;
						float: left;
						flex: 0 0 auto;
						margin-top: -0.1em;
						margin-right: 0.35em;
						margin-left: 0;

						@media (min-width: $large-screen-min) {
							margin-left: 0;
							margin-right: 0.6em;
							margin-top: 0;
						}
					}

					.c_nav-list-item__arrow-right {
						height: 0.7em;
						width: 0.385em;
						float: right;
						flex: 0 0 auto;
						margin-top: -0.1em;
						margin-right: 0.3em;

						@media (min-width: $large-screen-min) {
							margin-right: 0;
						}
					}

					.c_nav-list-item__redirect-icon {
						height: 0.7em;
						width: 0.7em;
						float:right;
						flex: 0 0 auto;
						margin-top: -0.1em;
						margin-right: 0.3em;

						@media (min-width: $large-screen-min) {
							margin-right: 0;
						}
					}
				}

				button.c_nav-list__item-content, a.c_nav-list__item-content {
					@include hover() {
						span {
							text-decoration: underline;
						}
					}
				}

				a, link, a:visited {
					color: $color--black;
					text-decoration: none;
				}

				&--two-column {
					font-size: 0.889em;
					display: block;
					border-bottom: 1px solid $color-miljostatus--menu-border-grey;

					@media (min-width: $large-screen-min) {
						column-break-inside: avoid;
						-webkit-column-break-inside: avoid;
					}

					button .c_nav-list-item__arrow-right {
						height: 0.55em;
					}
				}

				&--sub-level {
					font-size: 1em;
					border-bottom-color: $color-miljostatus--menu-border-grey;
				}

				&--level-block1 {
					&:first-of-type {
						border-top: 1px solid $color-miljostatus--menu-border-grey;
						padding-top: 0;
						margin-top: 1em;
					}
				}

				&--level-block3 {
					@media (min-width: $large-screen-min) {
						span {
							font-size: 0.777em;
						}
					}

					.c_nav-list__item-content {
						padding-top: 0.77em;
						padding-bottom: 0.77em;
						font-family: $light-font-family !important;
					}

					.c_nav-list-item__arrow-right {
						height: 0.5em !important;
						width: 0.3em !important;
					}
				}

				.c_nav-list-item__warning-icon {
					width: 1.7em;
					height: 1.7em;
					margin-right: 0.7em;
				}
			}

			li[class="c_nav-list__item c_nav-list__item--level-block2"],
			li[class="c_nav-list__item c_nav-list__item--level-block3"],
			li[class="c_nav-list__item c_nav-list__item--level-block4"] {
				margin-top: 2em;
				border-top: 1px solid $color-miljostatus--menu-border-grey;

				@media (min-width: $large-screen-min) {
					// margin-top: 3.23em;
				}
			}

			li[class="c_nav-list__item c_nav-list__item--level-block2"] ~ li[class="c_nav-list__item c_nav-list__item--level-block2"],
			li[class="c_nav-list__item c_nav-list__item--level-block3"] ~ li[class="c_nav-list__item c_nav-list__item--level-block3"],
			li[class="c_nav-list__item c_nav-list__item--level-block4"] ~ li[class="c_nav-list__item c_nav-list__item--level-block4"]{
				margin-top: 0;
				border-top: none;
			}
		}
	}
}

.c_master-nav--open {
	.c_master-nav__menu .bm-menu {
		overflow-y: scroll !important;
		-ms-overflow-style: none !important;
	}
}

body.u_master-nav-menu-open {
	@media (max-width: $small-screen-max) {
		overflow: hidden;
		position: fixed;
		top: 0;

		.c_master-header {
			overflow: hidden;
		}
	}
}

/* IE9 fix */
body.u_master-nav-menu-open .c_master-nav {
	@media (min-width: $large-screen-min) {
		opacity: 1;
	}
}