@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';
@import '../../../styles/_utilities.scss';
@import '../../../styles/_colors.scss';
@import '../../../styles/_colorsMiljostatus.scss';
@import '../../../styles/colorsVannportalen.scss';

.c_master-header {
	background-color: transparent;
	width: 100%;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
	position: relative;

	@media (max-width: $small-screen-max) {
		overflow: hidden;
	}

	@media (min-width: $large-screen-min) {
		&.c_master-header--vannportalen-frontpage {
			background: transparent linear-gradient(180deg, $color-vannportalen--header-gradient-start 0%, $color-vannportalen--header-gradient-stop 100%) 0% 0% no-repeat padding-box;
			margin-bottom: 2.4em;
		}
		box-shadow: none;
		padding-bottom: 2em;
	}

	.c_master-header__home-link {
		display: block;
		overflow: auto;
		float: left;
		text-decoration: none;

		@media (min-width: $large-screen-min) {
			margin-right: 23px;
		}

		&--search-active {
			width: 7em;
			overflow-x: hidden;
		}
	}

	.c_master-header__logo {
		width: 9em;
		height: 2em;
		margin: 1em 0 0.5em 0;

		&--mobile {
			width: 11em;
			height: 4em;
			margin: 0;

			@media (min-width: $large-screen-min) {
				display: none;
			}
		}

		&--desktop {
			display: none;
			/* height: 127px;
			width: 127px; */
			height: 125px;
			width: 400px;
			margin: 0;

			@media (min-width: $large-screen-min) {
				display: block;
			}
		}
	}

	.c_master-header__content-wrapper {
		display: block;
		width: auto;
		height: 4em;
		overflow: hidden;

		@media (min-width: $large-screen-min) {
			height: 6.444em;
			// border-bottom: 1px solid $color-miljostatus--grey-blue-3;
		}

		@media print {
			display: none;
		}
	}

	.c_master-header__actions-wrapper {
		display: flex;
		justify-content: flex-end;

		@media (min-width: $large-screen-min) {
			position: absolute;
			bottom: 1em;
			right: 10px;
			width: calc(100% - 160px);
		}
	}

	.c_search-field-suggestions {
		width: calc(100% - 7.7em);
		box-sizing: border-box;
		background-color: $color--background-white;
		border: 1px solid #8097A8;
		position: absolute;
		top: 3.1em;
		left: 0.45em;
		z-index: 666;

		&__list {
			margin: 0;
			padding: 0;
			list-style-type: none;
			background-color: $color--bright-white;
		}

		&__list-item {
			margin: 0;
			overflow: auto;

			a {
				display: block;
				overflow: auto;
				width: auto;
				color: $color--black;
				background-color: $color--bright-white;
				margin: 0;
				text-align: left;
				font: {
					family: $base-font-family;
					size: 1em;
				}
				padding: 1em;
				transition: all 0.25s ease;

				@include hover() {
					background-color: rgba($color-miljostatus--grey-blue-2, 0.55);

					span {
						text-decoration: underline;
					}
				}

				&:focus {
					background-color: rgba(yellow, 0.2);
				}

				span {
					display: block;
				}

				.name {
					float: left;
					text-decoration: underline;
				}

				.hit-category {
					color: $color--warm-grey;
					float: right;
				}
			}
		}

		.c_loading-spinner {
			margin: 1.6em auto;
			svg {
				width: 2.5em;
				height: 2.5em;
			}
		}
	}
}

.c_nav-toggle-button {
	@include removeButtonAppearance();
	border-left: 1px solid $color--white;
	cursor: pointer;
	font: {
		family: $base-font-family;
		size: 1em;
	}
	outline: none;
	padding: 1.65em 1.85em 1.1em 1.4em;
	position: absolute;
	right: -$container-padding;
	bottom: 0;
	max-height: 4em;
	margin: 0;
	box-sizing: border-box;
	transition: hover 0.3s ease, outline 0.1s ease;

	@media (min-width: $large-screen-min) {
		background-color: $color-vannportalen--blue-dark;
		border: none;
		color: $color--bright-white;
		padding: 1.1em 1.1em 0.85em 1.1em;
		position: relative;
		right: 0;
		min-height: 2.944em;

		@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
			/* IE10+ specific styles go here */
			height: 58px;
		}
	}

	@include hover() {
		background-color: $color-miljostatus--dark-blue-2;

		.c_nav-toggle-button__text {
			text-decoration: underline;
		}
	}

	&:focus {
		outline: $color-miljostatus--focus-orange solid $focus-outline-width;
		@media (min-width: $large-screen-min) {
			background-color: $color-miljostatus--dark-blue-2;
		}
	}

	.c_nav-toggle-button__text {
		margin-right: 0.833em;
	}

	.c_nav-toggle-button__icon {
		width: 1.111em;
		height: 0.97em;
		margin-bottom: -0.1em;

		&--close-menu {
			width: 0.889em;
		}
	}
}

.c_mobile-header-search-button {
	@include removeButtonAppearance();
	background: $color--bright-white;
	border-left: 1px solid #f0f0f0;
	font-size: 1em;

	padding: 1.5em 0.889em 0.8em 1.222em;
	position: absolute;
	right: 4.167em;
	bottom: 0;
	max-height: 4em;
	margin: 0;
	box-sizing: border-box;

	svg {
		width: 1.4em;
		height: 1.3em;
	}
}

.c_mobile-search-panel {
	background-color: $color-vannportalen--blue-light-1;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	z-index: 999;
	animation: slidePanelIn 0.4s ease-out 1 forwards;

	&--close {
		animation: slidePanelOut 0.4s ease-out 1 forwards;
	}

	.c_close-button {
		@include removeButtonAppearance();
		font: {
			family: $light-italic-font-family !important;
			size: 0.845em;
		}
		padding: 0.5em;
		position: absolute;
		right: 0.6em;
		top: 1em;

		.c_close-button__inner-wrapper {
			float: right;

			.c_close-button__text {
				display: inline-block;
				margin-top: -0.12em;
			}

			svg {
				height: 0.88em;
				width: 0.88em;
				margin-left: 0.88em;
			}
		}
	}

	.c_search-field {
		position: absolute;
		width: 93%;
		left: 0;
		right: 0;
		top: 3.5em;

		&__input:focus {
			background-color: $color--bright-white !important;
		}
	}

	.c_search-field-suggestions {
		top: 6.6em;
		left: 0;
		right: 0;
		width: 93%;
		margin: auto;

		&__list-item {
			border-bottom: 1px solid $color--pinkish-grey;

			&:last-of-type {
				border: none;
			}

			.name {
				width: 56%;
				margin-right: 4%;
			}

			.hit-category {
				width: 40%;
				text-align: right;
			}
		}
	}
}

@keyframes slidePanelIn {
	from { transform: translateX(100%) }
	to { transform: translateX(0) }
}

@keyframes slidePanelOut {
	from { transform: translateX(0) }
	to { transform: translateX(100%) }
}
